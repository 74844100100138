.dashboard-grid {
    width: 100%;
    height: 100%;
}


.threshold-shine:after {
    content: '';
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255, .15), transparent);
    animation-name: shining;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes shining {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(-100%);;
    }
    100% {
      transform: translateX(100%);
    }
  }
