#frame {
	width: 100%;
	height: calc(100vh - 120px);
	min-height: 300px;
	background: #e6eaea;
}

@media screen and (max-width: 360px) {
	#frame {
		width: 100%;
		height: 100vh;
		margin: 0px;
	}
}

#frame #sidepanel {
	float: right;
	min-width: 280px;
	max-width: 340px;
	width: 40%;
	height: 100%;
	background: #547399;
	color: #f5f5f5;
	overflow: hidden;
	position: relative;
}

#frame #sidepanel #profile {
	margin: 5px;
}

#frame #sidepanel #profile.expanded .wrap {
	height: 210px;
	line-height: initial;
}

#frame #sidepanel #profile.expanded .wrap p {
	margin-top: 20px;
}

#frame #sidepanel #profile.expanded .wrap i.expand-button {
	-moz-transform: scaleY(-1);
	-o-transform: scaleY(-1);
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
}

#frame #sidepanel #profile .wrap {
	height: 60px;
	line-height: 60px;
	overflow: hidden;
	-moz-transition: 0.3s height ease;
	-o-transition: 0.3s height ease;
	-webkit-transition: 0.3s height ease;
	transition: 0.3s height ease;
}

#frame #sidepanel #profile .wrap img {
	width: 50px;
	border-radius: 50%;
	padding: 3px;
	border: 2px solid #e74c3c;
	height: auto;
	float: left;
	cursor: pointer;
	-moz-transition: 0.3s border ease;
	-o-transition: 0.3s border ease;
	-webkit-transition: 0.3s border ease;
	transition: 0.3s border ease;
}

#frame #sidepanel #profile .wrap img.online {
	border: 2px solid #2ecc71;
}

#frame #sidepanel #profile .wrap img.away {
	border: 2px solid #f1c40f;
}

#frame #sidepanel #profile .wrap img.busy {
	border: 2px solid #e74c3c;
}

#frame #sidepanel #profile .wrap img.offline {
	border: 2px solid #95a5a6;
}

#frame #sidepanel #profile .wrap p {
	float: left;
	margin-left: 15px;
}

#frame #sidepanel #profile .wrap i.expand-button {
	float: right;
	margin-top: 23px;
	font-size: 0.8em;
	cursor: pointer;
	color: #435f7a;
}

#frame #sidepanel #profile .wrap #status-options {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	width: 150px;
	margin: 70px 0 0 0;
	border-radius: 6px;
	z-index: 99;
	line-height: initial;
	background: #435f7a;
	-moz-transition: 0.3s all ease;
	-o-transition: 0.3s all ease;
	-webkit-transition: 0.3s all ease;
	transition: 0.3s all ease;
}

#frame #sidepanel #profile .wrap #status-options.active {
	opacity: 1;
	visibility: visible;
	margin: 75px 0 0 0;
}

#frame #sidepanel #profile .wrap #status-options:before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 8px solid #435f7a;
	margin: -8px 0 0 24px;
}

#frame #sidepanel #profile .wrap #status-options ul {
	overflow: hidden;
	border-radius: 6px;
}

#frame #sidepanel #profile .wrap #status-options ul li {
	padding: 15px 0 30px 18px;
	display: block;
	cursor: pointer;
}

#frame #sidepanel #profile .wrap #status-options ul li:hover {
	background: #496886;
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 5px 0 0 0;
}

#frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
	content: "";
	position: absolute;
	width: 14px;
	height: 14px;
	margin: -3px 0 0 -3px;
	background: transparent;
	border-radius: 50%;
	z-index: 0;
}

#frame #sidepanel #profile .wrap #status-options ul li p {
	padding-left: 12px;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
	background: #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
	border: 1px solid #2ecc71;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
	background: #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
	border: 1px solid #f1c40f;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
	background: #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
	border: 1px solid #e74c3c;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
	background: #95a5a6;
}

#frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
	border: 1px solid #95a5a6;
}

#frame #sidepanel #profile .wrap #expanded {
	padding: 100px 0 0 0;
	display: block;
	line-height: initial !important;
}

#frame #sidepanel #profile .wrap #expanded label {
	float: left;
	clear: both;
	margin: 0 8px 5px 0;
	padding: 5px 0;
}

#frame #sidepanel #profile .wrap #expanded input {
	border: none;
	margin-bottom: 6px;
	background: #32465a;
	border-radius: 3px;
	color: #f5f5f5;
	padding: 7px;
	width: calc(100% - 43px);
}

#frame #sidepanel #profile .wrap #expanded input:focus {
	outline: none;
	background: #435f7a;
}

#frame #sidepanel #search {
	border-top: 1px solid #758ca4;
	border-bottom: 1px solid #758ca4;
	font-weight: 300;
}

#frame #sidepanel #search label {
	position: absolute;
	margin: 10px 0 0 20px;
}

#frame #sidepanel #search input {
	font-family: "proxima-nova", "Source Sans Pro", sans-serif;
	padding: 10px 0 10px 46px;
	width: calc(100% - 25px);
	border: none;
	background: #32465a;
	color: #f5f5f5;
}

#frame #sidepanel #search input:focus {
	outline: none;
	background: #435f7a;
}

#frame #sidepanel #search input::-webkit-input-placeholder {
	color: #f5f5f5;
}

#frame #sidepanel #search input::-moz-placeholder {
	color: #f5f5f5;
}

#frame #sidepanel #search input:-ms-input-placeholder {
	color: #f5f5f5;
}

#frame #sidepanel #search input:-moz-placeholder {
	color: #f5f5f5;
}

#frame #sidepanel #contacts {
	height: calc(100% - 177px);
	overflow-y: auto;
	overflow-x: hidden;
}

#frame #sidepanel #contacts.expanded {
	height: calc(100% - 334px);
}

#frame #sidepanel #contacts::-webkit-scrollbar {
	width: 8px;
	background: #2c3e50;
}

#frame #sidepanel #contacts::-webkit-scrollbar-thumb {
	background-color: #243140;
}

#frame #sidepanel #contacts ul li.contact {
	position: relative;
	font-size: 0.9em;
	cursor: pointer;
	padding-top: 5px;
    padding-bottom: 5px;
}

#frame #sidepanel #contacts ul {
	margin: 0;
}

#frame #sidepanel #contacts ul li.contact:hover {
	background:#3a6692;
}

#frame #sidepanel #contacts ul li.contact.active {
	background: #3a6692;
}

#frame #sidepanel #contacts button:hover {
	background:#3a6692;
}

#frame #sidepanel #contacts button.active {
	background:#3a6692;
}

#frame #sidepanel #contacts ul li.contact.active span.contact-status {
	border: 2px solid #32465a !important;
}

#frame #sidepanel #contacts ul li.contact .wrap {
	width: 88%;
	margin: 0 auto;
	position: relative;
}

#frame #sidepanel #contacts ul li.contact .wrap span {
	position: absolute;
	left: 0;
	margin: -2px 0 0 -2px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid #2c3e50;
	background: #95a5a6;
}

#frame #sidepanel #contacts ul li.contact .wrap span.online {
	background: #2ecc71;
}

#frame #sidepanel #contacts ul li.contact .wrap span.away {
	background: #f1c40f;
}

#frame #sidepanel #contacts ul li.contact .wrap span.busy {
	background: #e74c3c;
}

#frame #sidepanel #contacts ul li.contact .wrap img {
	width: 40px;
	border-radius: 50%;
	float: left;
	margin-right: 10px;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta {
	padding: 0 0 0 0;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .name {
	font-weight: 600;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
	font-size: 10px;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-moz-transition: 1s all ease;
	-o-transition: 1s all ease;
	-webkit-transition: 1s all ease;
	transition: 1s all ease;
	color: #1d2e6d;
}

#frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
	position: initial;
	border-radius: initial;
	background: none;
	border: none;
	padding: 0 2px 0 0;
	margin: 0 0 0 1px;
	opacity: 0.5;
}

#frame #sidepanel #bottom-bar {
	position: absolute;
	width: 100%;
	bottom: 0;
}

#frame #sidepanel #bottom-bar button {
	float: left;
	border: none;
	width: 50%;
	padding: 10px 0;
	background: #32465a;
	color: #f5f5f5;
	cursor: pointer;
	font-size: 0.85em;
	font-family: "proxima-nova", "Source Sans Pro", sans-serif;
}

#frame #sidepanel #bottom-bar button:focus {
	outline: none;
}

#frame #sidepanel #bottom-bar button:nth-child(1) {
	border-right: 1px solid #2c3e50;
}

#frame #sidepanel #bottom-bar button:hover {
	background: #435f7a;
}

#frame #sidepanel #bottom-bar button i {
	margin-right: 3px;
	font-size: 1em;
}

#frame .content {
	height: 100%;
	overflow: hidden;
	position: relative;
}

#frame .content .contact-profile {
	width: 100%;
	height: 60px;
	line-height: 60px;
	background: #f5f5f5;
}

#frame .content .contact-profile .dropdown-menu {
	line-height: 0.5rem;
}

#frame .content .contact-profile img {
	width: 40px;
	border-radius: 50%;
	float: left;
	margin: 9px 12px 0 9px;
}

#frame .content .contact-profile p {
	float: left;
}

#frame .content .contact-profile .social-media {
	float: right;
}

#frame .content .contact-profile .social-media i {
	margin-left: 14px;
	cursor: pointer;
}

#frame .content .contact-profile .social-media i:nth-last-child(1) {
	margin-right: 20px;
}

#frame .content .contact-profile .social-media i:hover {
	color: #435f7a;
}

#frame .content .messages {
	height: calc(100% - 136px);
	height: calc(100% - 136px);
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
}

#frame .content .messages::-webkit-scrollbar {
	width: 8px;
	background: transparent;
}

#frame .content .messages::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
}

#frame .content .messages ul li {
	display: inline-block;
	clear: both;
	float: left;
	margin: 0px 15px 0px 15px;
	width: calc(100% - 25px);
	font-size: 0.9em;
}

#frame .content .messages ul li:nth-last-child(1) {
	margin-bottom: 20px;
}

#frame .content .messages ul li div.replies img {
	margin: 6px 8px 0 0;
}

#frame .content .messages ul li div.replies p {
	background: #435f7a;
	color: #f5f5f5;
}

#frame .content .messages ul li div.sent img {
	float: right;
	margin: 6px 0 0 8px;
}

#frame .content .messages ul li div.sent p {
	background: #f5f5f5;
	float: right;
}

#frame .content .messages ul li img {
	width: 22px;
	border-radius: 50%;
	float: left;
}

#frame .content .messages ul li p {
	display: inline-block;
	padding: 10px 15px;
	border-radius: 20px;
	max-width: 205px;
	line-height: 130%;
	font-size: 16px;
	margin-bottom: 5px;
}

@media screen and (min-width: 735px) {
	#frame .content .messages ul li p {
		max-width: 300px;
	}
}

#frame .content .message-input {
	position: relative;
	width: 100%;
	z-index: 99;
}

#frame .content .message-input .wrap {
	position: relative;
}

#frame .content .message-input .wrap input {
	font-family: "proxima-nova", "Source Sans Pro", sans-serif;
	float: left;
	border: none;
	width: calc(100% - 50px);
	padding: 14px 32px 8px 8px;
	font-size: 0.8em;
	color: #32465a;
	font-size: 16px;
	height: 48px;
    border-width: 0;
}

#frame .content .message-input .wrap input:focus {
	outline: none;
}

#frame .content .message-input .wrap .attachment {
	position: absolute;
	right: 60px;
	z-index: 4;
	margin-top: 10px;
	font-size: 1.1em;
	color: #435f7a;
	opacity: 0.5;
	cursor: pointer;
}

#frame .content .message-input .wrap .attachment:hover {
	opacity: 1;
}

#frame .content .message-input .wrap button {
	float: right;
	border: none;
	width: 50px;
	padding: 12px 0;
	cursor: pointer;
	background: #3a6692;
	color: #f5f5f5;
	height: 48px;
}

#frame .content .message-input .wrap button:hover {
	background: #435f7a;
}

#frame .content .message-input .wrap button:focus {
	outline: none;
}

#contacts ul {
	list-style-type: none;
	padding-left: 0;
}

.contact p {
    margin-bottom: 0;
}

@media only screen and (max-width: 767.98px) {
	#frame {
		height: calc(100% - 60px);
	}

	#frame #sidepanel {
		height: calc(100% - 60px);
	}

	.container-fluid {
		overflow-x: hidden;
	}

	#frame #sidepanel {
		position: absolute;
		right: 0;
		z-index: 100;
		margin-right: -320px;
	}

	.toggle-contacts{
		position: absolute;
		top: 150px;
		right: 0;
		color: #000;
		z-index: 100;
	}

	.toggle-contacts {
		background: lightgray;
		border: 1px solid gray;
		border-bottom: 0;
		padding: 2px;
		padding-bottom: 10px;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}

	.vertical-text {
		transform: rotate(-90deg);
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		margin-right: -20px;
	}
  }

#frame .contact-group {
	font-size: 11px;
}