.multi-state-checkbox {
    position: relative;
    border: solid 1px hsl(0,0%,80%);
    border-radius: 3px;
    width: 25px;
    height: 25px;
    
    -webkit-transition: all 100ms;
    transition: all 100ms;
}

.multi-state-checkbox:focus {
    outline: unset;
    border: solid 1px #2684FF;
}

.multi-state-checkbox:focus:hover {
    outline: unset;
    border: solid 1px #2684FF;
}

.multi-state-checkbox:hover {
    border: solid 1px hsl(0,0%,70%);
}

.multi-state-checkbox .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.multi-state-checkbox.invalid {
    border-color: #c4183c;
    box-shadow: 0 5px 11.5px rgba(196,24,60,.1);
}

.multi-state-checkbox-container {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.multi-state-checkbox-label {
    font-size: 10px;
}
