.collapse-header-nav {
    color: white;
    background-color: #007bff;
    padding: 10px;
    font-family: Arial;
    cursor: pointer;
    user-select: none;
    /* standard syntax */
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}

.collapse-header-nav>.icon-container {
    display: inline-flex;
}

.collapse-header-nav>.icon-container>.nav-collapse-icon {
    margin-right: 5px;
}

.icon-container {
    display: inline-flex;
}

.icon-container>.nav-collapse-icon {
    margin-right: 5px;
}

.card-header {
    padding: 15px 1.875rem;
}

.small-padding {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
}

.device-card-details {
    font-size: 11px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    display: block;
    width: 100%;
}

.input-daterange>div {
    width: 50%;
}

.react-datepicker__tab-loop {
    display: contents;
}

.expand-row {
    background: red;
    border-bottom: red;
}

.expand-content {
    background: red;
    border-top: 0;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

.react-bootstrap-table table td {
    cursor: pointer;
}

.react-bootstrap-table table td * {
    cursor: context-menu;
}

.react-bootstrap-table-header {
    background-color: dodgerblue;
    color: white;
}

.detail-row-container {
    line-height: 1.0;
}

.details-row-title {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: bold;
    color: #007bff;
}

.details-row-content{
    overflow-wrap: anywhere;
}

.divider {
    margin: 6px 0px 6px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}


/* Shards Modal Fixes */

.modal {
    z-index: 1080;
    height: inherit;
}

.modal-backdrop {
    z-index: 1079;
    background-color: #5a6169;
}

.modal-content {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

/* Shards Tooltip Fixes */

.tooltip-modal {
    z-index: 1080;
}

/* React-Select shards form fit */

.react-select-container {
    font-size: .8125rem;
    height: auto;
    min-height: 35px;
    max-height: fit-content;
    width: 100%;
}

.react-select__control {
    height: auto;
    min-height: 35px !important;
    align-content: center;
    box-shadow: none !important;
}

.react-select__indicator {
    height: 33px;
    align-items: center;
}

.react-select-container.has-error>.react-select__control {
    border-color: #c4183c !important;
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, .1) !important;
}

.react-select-container.has-error>.react-select__control:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, .1), 0 1px 1px 0.1rem rgba(196, 24, 60, .2) !important;
}

.react-select-container.has-error .react-select__indicator {
    color: #c4183c !important;
}

input.has-error {
    border-color: #c4183c !important;
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, .1) !important;
}

input.has-error:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 5px 11.5px rgba(196, 24, 60, .1), 0 1px 1px 0.1rem rgba(196, 24, 60, .2) !important;
}

.react-select__menu {
    z-index: 10;
    margin-top: 2px !important;
}


/* LinkButton styles */

.link-button {
    background-color: #ffffff00;
    color: #007bff;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.link-button:hover {
    color: #0056b3;
}

.link-button:focus {
    outline: 0;
}

.link-button:disabled {
    background-color: #ffffff00;
    color: #868e96;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: default;
}

.link-button:disabled:hover {
    color: #868e96;
}

.back-button {
    height: 100%;
    float: left;
    margin-right: 5px;
}

.back-button-icon {
    cursor: pointer;
}

.back-button-icon:hover {
    cursor: pointer;
    color: #007bff;
}

.avatar-icon {
    cursor: pointer;
}

.avatar-icon:hover {
    cursor: pointer;
    color: #0b9bbe;
}

.avatar-icon:hover>span {
    color: #0b9bbe;
}

.avatar-icon:hover>svg {
    color: #0b9bbe;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-error-message {
    padding-inline-start: 15px;
    font-size: 12px;
    font-family: initial;
    color: #c4183c;
}

.react-select-single-value {
    color: inherit;
    cursor: default;
    display: flex;
    justify-content: space-between;
    font-size: inherit;
    padding: 0;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.react-select-option {
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 0 0 0 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.react-select-option-trash-container {
    width: 42px;
    height: 36px;
    color: hsl(0, 0%, 40%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    box-sizing: border-box;
}

.react-select-option-trash-container:hover {
    color: hsl(0, 0%, 20%);
}

.react-select-option-trash-icon {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    margin: auto;
}

.react-select-option-trash-separator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
}

.filter-label {
    font-weight: 600;
}

.small-text {
    font-size: small;
}

.table thead th {
    vertical-align: middle;
}

.table td,
.table th {
    padding: 10px 16px 10px 16px;
}

.overflow-y-inherit {
    overflow-y: inherit !important;
}

.Toastify__toast-container {
    position:fixed; 
    z-index:999999;
    pointer-events:none
}

.Toastify__toast-container *{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}

.Toastify__toast-container>div {position:relative;pointer-events:auto;overflow:hidden;margin:0 0 6px;padding:15px 15px 15px 50px;width:300px;-moz-border-radius:3px;-webkit-border-radius:3px;border-radius:3px;background-position:15px center;background-repeat:no-repeat;-moz-box-shadow:0 0 12px #999;-webkit-box-shadow:0 0 12px #999;box-shadow:0 0 12px #999;color:#FFF;opacity:.8;-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80);filter:alpha(opacity=80)}

.Toastify__toast--success {
    background: #51A351 !important;
 }

 .Toastify__toast--error {
    background: #BD362F !important;
 }

 .toast-title {
    font-weight:700
}

.toast-message {
    -ms-word-wrap:break-word;
    word-wrap:break-word
}

.toast-message a,.toast-message label {
    color:#FFF
}

.toast-message a:hover {
    color:#CCC;
    text-decoration:none
}

.pagination.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

@media (min-width: 576px) {
    .modal-xl {
        max-width: 80%;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 950px;
    }
}

.gray-background {
    background-color:rgba(0,0,0,.075);
}

.column-header:focus {
    outline: none;
}

.schedule-timing-form label {
    margin: 0;
    margin-right: .35rem;
}

.schedule-timing-form .custom-radio {
    padding-left: 1.2rem;
}

.form-checkbox-center {
    position: absolute;
    top: 35px;
    margin-left: 12px;
}

.custom-toggle .custom-control-input:checked~.custom-control-label::before {
    background: #007bff;
    border-color: #0162cb;
}

.thumbnail-container {
    cursor: pointer;
}

.thumbnail-container:hover {
    text-decoration: underline;
}

.input-group-sm > .input-group-append > .btn {
  padding: .25rem .5rem;
}

.dictionary-preview {
    max-height: 65px;
    overflow-y: scroll;
}

.bootstrap-table-actions-col {
    width: 90px;
    margin-left: auto; 
    margin-right: 0;
}

.without-border {
    border: 0
}

.configureDashboardTileFormMenuRow {
    width: 100%;
    display: flex;
}

.configureDashboardTileFormMenuOption {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
}

/* .configureDashboardTileFormMenuOption:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.configureDashboardTileFormMenuOption:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
} */

.configureDashboardTileFormMenuOption:first-child {
    border-right: 0.1rem solid rgba(235, 235, 235, 1);
}

.configureDashboardTileFormMenuOption:nth-child(2) {
    border-left: 0.1rem solid rgba(235, 235, 235, 1);
    border-right: 0.1rem solid rgba(235, 235, 235, 1);
}

.configureDashboardTileFormMenuOption:last-child {
    border-left: 0.1rem solid rgba(235, 235, 235, 1);
}

.configureDashboardTileFormMenuOption > p {
    margin: 0;
}


/* Sidebar styles */

.sidebar-burger-wrapper {
    font-size: 1.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 33%;
    left: 89%;
    z-index: 1071;
    cursor: pointer;
}

.sidebar-burger-wrapper.hidden {
    width: 1.3rem;
    height: 100vh;
    opacity: 0.3;
    background-color: rgba(0,0,0,0.2);
    top: 0;
    left: 100%;
}

.sidebar-burger-wrapper.hidden:hover {
    opacity: 1;
}

.sidebar--transition {
    transform: translateX(0);
    transition: transform .3s ease-in-out;
}

.sidebar--transition.hidden {
    transform: translateX(-100%);
}